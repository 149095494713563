/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { array, arrayOf, bool, number, object, oneOfType, string } from 'prop-types';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import 'url-search-params-polyfill';
import ErrorBoundary from '../../components/ErrorBoundary';
import { getCentsFromDollars, getCentsFromMaskedMoneyInput, getDollarsFromCents } from '../../lib/monetaryUtils';
import AmountComponent from './Amount';
import BusinessInformationComponent from './BusinessInformationComponent';
import CustomFieldsComponent from './CustomFields';
import FooterComponent from './Footer';
import LoanThankYou from './LoanThankYou';
import PaymentInformationComponent from './PaymentInformation';
import './paymentPageStyle.scss';
import ThankYouComponent from './ThankYou';
import { getChargeReceipt, processSignatureLink } from './helpers/services';
import SignatureComponent from './Signature';
import { rollbarLog, safeSendGA } from '../../lib/utils';
import { getSelectedCustomFieldsWithoutSections } from './helpers/submitBuilders';
import { getDefaultValuesFromUrl, getDisabledFieldsFromUrl } from './helpers/urlParams';
import MerchantLogo from './MerchantLogo';
import FrequencyComponent from './Frequency';

const sendGAPaymentSuccessEvent = (method, isSurcharged) => {
  if (method === 'loan') {
    safeSendGA('UPF', 'Pay Later: Application Approved', 'Payment-Page');
    return;
  }
  const isSurchargedLabel = isSurcharged ? 'Surcharge applied' : 'No surcharge';
  safeSendGA('UPF', `Payment Successful: ${method === 'card' ? 'Card' : 'eCheck'} ${isSurchargedLabel}`, 'Payment-Page');
};

const UpfPaymentPage = ({
  title,
  merchant,
  hide_location_info,
  logo,
  description,
  notes,
  reference_label,
  reference_required,
  current_user_id,
  payment_page_id,
  amount_fields,
  custom_fields,
  recurring_visible,
  token,
  bank_account_id,
  bank_account_currency,
  public_key,
  redirect_url,
  signature,
  signature_text,
  payment_page_url,
  surcharge_disabled,
  test_mode,
  disabled_payment_methods,
  api_gw_url
}) => {

  const upfRef = useRef();

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), [window.location.search]);
  const disabledFields = useMemo(() => getDisabledFieldsFromUrl(urlParams), [urlParams]);
  const defaultFields = useMemo(() => getDefaultValuesFromUrl(urlParams), [urlParams]);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSignature, setIsSignature] = useState(false);
  const [chargeResponse, setChargeResponse] = useState();
  const [dataSignature, setDataSignature] = useState();

  const [amountState, setAmountState] = useState({
    amountField: defaultFields?.amount,
    customFieldsSubtotal: 0
  });

  const [amountFieldsData, setAmountFieldsData] = useState(amount_fields);

  const [paymentInfoState, setPaymentInfoState] = useState({reference: defaultFields?.reference});

  const [customFieldsState, setCustomFieldsState] = useState(() => {
    const fields = custom_fields.filter(field => field.custom_field !== 'reference');
    return fields.reduce((rest, field) => ({
      ...rest,
      ...(field.type === 'check_box' ? { [field.name]: undefined } : { [field.name]: defaultFields[field.name] || field.default_value || undefined })
    }), {});
  });

  const [customAmountFieldsState, setCustomAmountFieldsState] = useState();

  const [isThankYou, setIsThankYou] = useState(false);
  const [thankYouContent, setThankYouContent] = useState('');
  const [isLoanThankYou, setIsLoanThankYou] = useState(false);

  const centsAmountDue = useMemo(() => {
    return getCentsFromMaskedMoneyInput(amountState.amountField) + getCentsFromDollars(amountState.customFieldsSubtotal);
  }, [amountState]);

  const missingRequiredFields = useMemo(() => {
    // 2 - required custom field, 1 - field shown but optional, 0 - field hidden
    const requiredCustomFields = [...custom_fields, ...amount_fields].filter(field => field.name !== 'reference' && field.required === 2).map(field => field.name);
    const referenceMissing = reference_required === 2 && !paymentInfoState.reference;
    const allCustomFields = Object.entries({ ...customFieldsState, ...customAmountFieldsState });
    const missingCustomFields = allCustomFields.filter(([key, val]) => {
      const valueMissing = Array.isArray(val) ? !val[0] : !val;
      return requiredCustomFields.includes(key) && valueMissing;
    }).map(([key]) => key);
    return [
      ...missingCustomFields,
      ...(referenceMissing ? [reference_label || 'Reference'] : [])
    ];
  }, [paymentInfoState, customFieldsState, customAmountFieldsState, custom_fields, amount_fields]);

  useEffect(() => {
    if (/Trident\/|MSIE /.test(window.navigator.userAgent)) {
      document.location.href = '/unsupported';
      return;
    }
    safeSendGA('UPF', 'UPF Opened', 'Payment-Page');
  }, []);

  useEffect(() => {
    const handleSignatureLink = async () => {
      const signatureToken = urlParams.get('sig');
      if (signatureToken) {
        try {
          const res = await processSignatureLink(`/signatures/${signatureToken}`);
          if (res.ok) {
            const json = await res.json();
            // 3 - physical signature pad, 2 - electronic, 0 - no signature, 1 - line on receipt
            if ([2, 3].includes(signature)) {
              setChargeResponse(json.charge);
              setIsSignature(true);
            } else {
              setThankYouContent(json.receipt_html);
              setIsThankYou(true);
            }
          } else {
            rollbarLog('UPF Payment Page', `Failed to process 'resend signature' with non-ok: ${res.status}`, 'warning');
            window.location = payment_page_url;
          }
        } catch (err) {
          rollbarLog('UPF Payment Page', `Failed to reach server to process 'resend signature' with: ${err.status}`, 'warning');
          window.location = payment_page_url;
        }
      }
    };
    handleSignatureLink();
  }, [urlParams]);

  useEffect(() => {
    const chargeSuccessListener = (e) => {
      setIsSubmitting(true);
      // UPF charge stores amount in cents, VT backend stores it in dollars so have to convert here
      const chargePayload = { ...e.detail, amount: getDollarsFromCents(e.detail.amount) };
      sendGAPaymentSuccessEvent(chargePayload.method.type, !!chargePayload?.surcharge_amount);
      if (chargePayload.method.type === 'loan') {
        setIsLoanThankYou(true);
      } else {
        getReceipt(chargePayload);
      }
    };
    document.addEventListener('UPFChargeSuccess', chargeSuccessListener);
    return () => {
      document.removeEventListener('UPFChargeSuccess', chargeSuccessListener);
    };
  }, []);

  useEffect(() => {
    const paymentMethodChangeListener = (e) => {
      const { paymentMethod } = e.detail;
      if (paymentMethod === selectedPaymentMethod) return;
      setSelectedPaymentMethod(paymentMethod);
      if (amount_fields.length === 0) return;
      if (paymentMethod === 'credit_card') {
        setAmountFieldsData(amount_fields.filter(el => el.incompatible_payment_types.indexOf('card') === -1));
      } else if (paymentMethod === 'ach') {
        setAmountFieldsData(amount_fields.filter(el => el.incompatible_payment_types.indexOf('bank') === -1));
      }
    };
    document.addEventListener('UPFPaymentMethodChange', paymentMethodChangeListener);
    return () => {
      document.removeEventListener('UPFPaymentMethodChange', paymentMethodChangeListener);
    };
  }, [selectedPaymentMethod]);

  useEffect(() => {
    const customData = {
      payment_page_id,
      ...(!!current_user_id && { user_id: current_user_id }),
      ...((customFieldsState || customAmountFieldsState) && { custom_fields: getSelectedCustomFieldsWithoutSections({ ...customAmountFieldsState, ...customFieldsState }) })
    };
    const upfElement = upfRef.current;
    if (upfElement) upfElement['custom-data'] = customData;
  }, [customAmountFieldsState, customFieldsState, payment_page_id, current_user_id]);

  useEffect(() => {
    const upfElement = upfRef.current;
    if (upfElement) upfElement['default-fields'] = defaultFields;
  }, [defaultFields]);

  const onSignatureSuccess = (json, dataSignature) => {
    if (redirect_url) {
      window.location = redirect_url;
    } else {
      setDataSignature(dataSignature);
      setThankYouContent(json.receipt_html);
      setIsThankYou(true);
    }
  };

  const getReceipt = async (chargePayload) => {
    try {
      const res = await getChargeReceipt(`${api_gw_url}/v2/receipts/${chargePayload.id}`, token);
      if (res.ok) {
        const json = await res.json();
        const chargeAttributes = json.data.attributes;
        setChargeResponse(chargeAttributes);
        // 3 - physical signature pad, 2 - electronic, 0 - no signature, 1 - line on receipt
        if ([2, 3].includes(signature)) {
          setIsSignature(true);
        } else if (redirect_url) {
          window.location = redirect_url;
        } else {
          setThankYouContent(chargeAttributes.receipt_html);
          setIsThankYou(true);
        }
      } else {
        const json = await res.json();
        setIsThankYou(true);
        rollbarLog('UPF Payment Page', `Get receipt for charge error, response status: ${res.status}, body: ${JSON.stringify(json)}`);
      }
    } catch(err) {
      setIsThankYou(true);
      rollbarLog('UPF Payment Page', `getReceipt catch error: ${err.message}`);
    }
  };

  return (
    <ErrorBoundary>
      <div className="container upf-payment-page-container">
        {isThankYou ? (
          <div className="row" role="main">
            <ThankYouComponent
              content={thankYouContent}
              dataSignature={dataSignature}
              current_user_id={current_user_id}
              charge={chargeResponse}
              merchant={merchant}
              logo={logo}
            />
          </div>
        ) : (
          <>
            <div className="row">
              <MerchantLogo merchant={merchant} logo={logo} type='mobile' />
              <div className="col-12 col-md-4 business-info order-2 order-sm-1" role="complementary">
                <BusinessInformationComponent
                  {...merchant}
                  logo={logo}
                  notes={notes}
                  hideLocation={hide_location_info}
                />
              </div>
              <div className="col-12 col-md-8 order-1 order-sm-2">
                {!isLoanThankYou && (
                  <>
                    <header role="banner">
                      <h1>{title}</h1>
                      <strong>{description}</strong>
                      <div className="col-12 header-merchant-name">
                        <strong>{merchant.name}</strong>
                      </div>
                    </header>
                  </>
                )}
                {isSignature || isLoanThankYou ? (
                  <>
                    {isLoanThankYou ? (
                      <LoanThankYou
                        brand={merchant?.site?.title}
                      />
                    ) : (
                      <SignatureComponent
                        signature={signature}
                        charge={chargeResponse}
                        text={signature_text}
                        onSignatureSuccess={onSignatureSuccess}
                        currency={bank_account_currency}
                      />
                    )}
                  </>
                ) : (
                  <div role="main">
                    <div role="form">
                      <AmountComponent
                        formState={amountState}
                        setFormState={setAmountState}
                        customFieldsState={customAmountFieldsState}
                        setCustomFieldsState={setCustomAmountFieldsState}
                        fieldsData={amountFieldsData}
                        currency={bank_account_currency}
                        disabledFields={disabledFields}
                      />
                      <PaymentInformationComponent
                        formState={paymentInfoState}
                        setFormState={setPaymentInfoState}
                        referenceLabel={reference_label}
                        referenceRequired={reference_required}
                        disabledFields={disabledFields}
                      />
                      <CustomFieldsComponent
                        formState={customFieldsState}
                        setFormState={setCustomFieldsState}
                        customFieldsData={custom_fields}
                        disabledFields={disabledFields}
                      />
                      {recurring_visible && recurring_visible !== 'NONE' &&
                        <FrequencyComponent
                          recurringVisible={recurring_visible}
                          disabledFields={disabledFields}
                          defaultFields={defaultFields}
                          selectedPaymentMethod={selectedPaymentMethod}
                          upfRef={upfRef}
                        />
                      }
                      <div className="row">
                        <div className="col-12">
                          <h2>Payment Method</h2>
                        </div>
                      </div>
                      <upf-checkout
                        amount={centsAmountDue}
                        bank-account-id={bank_account_id}
                        token={token}
                        public-key={public_key}
                        submit-disabled={isSubmitting}
                        surcharge-disabled={surcharge_disabled}
                        reference={paymentInfoState.reference}
                        errors={missingRequiredFields.map(field => `${field} is required`).join(', ')}
                        test-mode={test_mode}
                        disabled-fields={disabledFields.join(',')}
                        disabled-payment-methods={disabled_payment_methods.join(',')}
                        ref={upfRef}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <FooterComponent site={merchant?.site?.identifier} />
          </>
        )}
      </div>
    </ErrorBoundary>
  );
};

UpfPaymentPage.propTypes = {
  title: string,
  merchant: object,
  merchant_account: object,
  logo: object,
  default_payment_method: string,
  description: string,
  notes: string,
  reference_label: string,
  reference_required: number,
  payment_page_url: string,
  redirect_url: string,
  current_user_id: string,
  payment_page_id: string,
  amount_fields: array,
  custom_fields: array,
  hide_location_info: bool,
  token: string,
  bank_account_id: string,
  bank_account_currency: string,
  public_key: string,
  signature: number,
  signature_text: string,
  surcharge_disabled: bool,
  test_mode: bool,
  api_gw_url: string,
  recurring_visible: oneOfType([string, bool]),
  disabled_payment_methods: arrayOf(string)
};

export default UpfPaymentPage;
