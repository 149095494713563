import React from 'react';
import { APButton } from 'affinipay-ui-library';
import IconEQB from '../../QuickBill/icons/icon-eqb';

const InvoicesQuickBillButton = () => {
  const onClick = () => {
    var openModalBtn = document.getElementById('new-quick-bill-modal-button');
    if ((window.location.pathname.indexOf('/invoices') > -1) && openModalBtn) {
      openModalBtn.click();
    } else {
      window.location.href = '/invoices/?openquickbill=true';
      return null;
    }
  };

  return (
    <>
      <APButton className={'ap-secondary-button send-quick-bill uppercase'} onClick={onClick} >
        <IconEQB
          className="send-quick-bill-icon"
          title="Send Quick Bill Icon"
          size="24"
        />
        <span className="quick-bill-button-text">Send Quick Bill</span>
      </APButton>
    </>
  );
};

export default InvoicesQuickBillButton;