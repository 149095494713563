import React, { useState } from 'react';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import client from 'lib/ajax';
import notify from 'lib/notify';
import UriTemplate from 'uri-templates';
import { APPopover, APButton, APInputMask } from 'affinipay-ui-library';

const numberMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: false
});

const ChangeApplication = ({applicationId, editable, paths}) => {
  const [updatedAppId, setUpdatedAppId] = useState('');
  const [currentAppId, setCurrentAppId] = useState(applicationId);
  const [hasError, setHasError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setUpdatedAppId(e.target.value);
  };

  const isValid = () => updatedAppId.length && updatedAppId.match(/^\d+$/);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const resp = await client.patch(paths.merchant, {application_id: updatedAppId});
      handleResponse(resp);
    } catch {
      notify.error('Something went wrong. Please contact Dev Team.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResponse = async (resp) => {
    const status = resp.status;
    const json = await resp.json();
    switch (status) {
    case 200:
      setCurrentAppId(json.merchant_application_id);
      setUpdatedAppId('');
      notify.success(json.message);
      break;
    case 401:
      notify.error('Not Authorized');
      break;
    case 403:
    case 404:
    case 422:
      setHasError(true);
      notify.error(json.message, json.errors);
      break;
    default:
      notify.error('Something went wrong. Please contact Dev Team.');
    }
  };

  return (
    <div className="col-xs-12 top-spacer-20">
      <h4>Merchant Application</h4>
      <div>
        Current Application:&nbsp;
        {currentAppId ? (
          <a
            href={UriTemplate(paths.merchantApplication).fill({
              id: currentAppId
            })}
          >
            {currentAppId}
          </a>
        ) : (
          <span className="text-danger">None</span>
        )}
      </div>

      {editable ? (
        <div>
          <APPopover 
            titleText='Merchant Application ID'
            buttonText='Change Application'
            popOverPosition='bottom'
          >
            <div className='change-application-container'>
              <APInputMask
                mask={numberMask}
                onFocus={() => setHasError(false)}
                type="text"
                onChange={handleChange}
                value={updatedAppId}
                error={hasError}
              />
              <APButton
                type="button"
                disabled={!isValid() || isSubmitting}
                onClick={handleSubmit}
              >
                Submit
              </APButton>
            </div>
          </APPopover>
        </div>
      ) : null}
    </div>
  );
};

ChangeApplication.propTypes = {
  applicationId: PropTypes.number,
  editable: PropTypes.bool,
  paths: PropTypes.shape({
    merchant: PropTypes.string,
    merchantApplication: PropTypes.string
  })
};

export default ChangeApplication;
