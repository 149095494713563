// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Admin Surcharge Component shallow snapshot render 1`] = `
<div
  className="form-group admin surcharge"
>
  <label
    className="col-sm-3 col-md-4 col-lg-3 control-label line-label"
  >
    Enable Surcharge
  </label>
  <div
    className="col-sm-9 col-md-8 col-sm-9 checkbox"
  >
    <APCheckbox
      checked={false}
      disabled={false}
      displayType="toggle"
      name="surcharge_enabled"
      onChange={[Function]}
      text="No Surcharge"
      value="true"
    />
  </div>
</div>
`;
