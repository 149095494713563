export const isTrustAccount = successfulPayment => {
  if (!successfulPayment) return false;
  return successfulPayment.payment?.bankAccountType === 'iolta';
};

export const defaultMonetaryOutput = {
  bankDetails: {
    bankAccount: undefined,
    bankName: undefined,
    isTrustAccount: false
  },
  paymentTotal: {
    amount: undefined,
    currency: undefined
  },
  depositDetails: {
    currency: undefined,
    surchargeAmount: undefined
  }
};

export const getMonetaryDetails = (invoice = {}) => {
  if (!Object.keys(invoice).length) return defaultMonetaryOutput;
  const { currency, totalAmount } = invoice;
  const findSuccessfulPayment = invoicePayment => ['authorized', 'completed'].includes(invoicePayment?.payment?.status);
  const successfulPayment = invoice?.invoicePayments.find(findSuccessfulPayment);

  if (!successfulPayment) return defaultMonetaryOutput;

  const { surchargeAmount, payment: { bankAccount, bankName } } = successfulPayment;

  return {
    bankDetails: {
      bankAccount,
      bankName,
      isTrustAccount: isTrustAccount(successfulPayment)
    },
    paymentTotal: {
      amount: totalAmount + (surchargeAmount || 0),
      currency
    },
    depositDetails: {
      currency,
      surchargeAmount
    }
  };
};

const reduceInvoiceForPaymentValues = (displayValues, invoice) => {
  if (!invoice || !Object.keys(invoice).length) return displayValues;

  const { id, invoiceDate, totalAmount, currency, contact, created, reference } = invoice;

  const invoiceOutput = {
    ...getMonetaryDetails(invoice),
    id,
    invoiceDate,
    amount: { subTotal: totalAmount, currency },
    nameReference: { displayName: contact?.displayName, reference },
    timestamp: created?.timestamp
  };

  return [...displayValues, invoiceOutput];
};

export const gatherPaymentValues = (invoices = []) =>  invoices.reduce(reduceInvoiceForPaymentValues, []);
