import { APButton, APInputMask, APInputText, APSelect } from 'affinipay-ui-library';
import { array, bool, func, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import APRadioButton from '../../components/APRadioButton';
import { usStates } from '../../lib/constants';
import { addClass, hasClass, removeClass, setSelectedInAPSelect, dateFormatUtc } from '../../lib/utils';
import './style.scss';

const AddOwnerForm = ({ errors, onSubmit, onCancel, owner, onEditSubmit, isAdmin, isAffiniPaySite, showPuertoRicoState }) => {
  const isAffiniPayApplicant = !isAdmin && isAffiniPaySite;

  const treasurerLabel = isAffiniPayApplicant ? 'CFO, Treasurer' : 'Treasurer';

  const jobTitles = [
    { text: 'President', value: 'President' },
    { text: 'Vice President', value: 'V.President' },
    { text: 'Owner', value: 'Owner' },
    { text: 'Partner', value: 'Partner' },
    { text: 'Manager', value: 'Manager' },
    { text: treasurerLabel, value: 'Treasurer' },
    { text: 'Secretary', value: 'Secretary' },
    { text: 'Executive Director', value: 'Exec.Director' }
  ];

  const newFormState = {
    first_name: '',
    last_name: '',
    has_significant_ownership: true,
    job_title: '',
    social_security_number: '',
    date_of_birth: '',
    home_address1: '',
    home_address2: '',
    home_city: '',
    home_state: '',
    home_zip_code: ''
  };

  let initialFormState, initialStatesOptions, initialJobsOptions;

  const usStatesFilteredFromPR = showPuertoRicoState ? usStates : usStates.filter(state => state.value !== 'PR');

  if (owner) {
    const editFormState = {
      id: owner.id,
      first_name: owner.first_name,
      last_name: owner.last_name,
      has_significant_ownership: !!owner.has_significant_ownership,
      job_title: owner.job_title,
      social_security_number: '',
      date_of_birth: dateFormatUtc(owner.date_of_birth),
      home_address1: owner.home_address1,
      home_address2: owner.home_address2,
      home_city: owner.home_city,
      home_state: owner.home_state,
      home_zip_code: owner.home_zip_code
    };

    initialJobsOptions = setSelectedInAPSelect(jobTitles, owner.job_title);
    initialStatesOptions = setSelectedInAPSelect(usStatesFilteredFromPR, owner.home_state);
    initialFormState = editFormState;
  } else {
    initialStatesOptions = usStatesFilteredFromPR;
    initialJobsOptions = jobTitles;
    initialFormState = newFormState;
  }

  const [formState, setFormState] = useState(initialFormState);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isValidOwner, setIsValidOwner] = useState(true);

  useEffect(() => {
    setIsFormValid(validateForm());
    removeSelectsValidationErrors();
  }, [formState]);

  const handleFieldChange = (e) => {
    const inputValue = e.target.value,
      inputName = e.target.name;
    if (Object.prototype.hasOwnProperty.call(formState, inputName)) {
      const fieldGroup = e.target.parentNode;
      removeFieldErrors(fieldGroup);
      const newState = {};
      newState[inputName] = inputValue;
      setFormState(prevState => {
        return { ...prevState, ...newState };
      });
    }
  };

  const verifyRequiredSelect = (fieldGroup, selectName, fieldName) => {
    if (!formState[selectName] && !hasClass(fieldGroup, 'has-error')) {
      addFieldError(fieldGroup, fieldName);
    }
  };

  const removeSelectsValidationErrors = () => {
    const requiredSelects = [
      'job_title',
      'home_state'
    ];
    requiredSelects.forEach((select) => {
      if (formState[select]) {
        const fieldGroup = document.querySelector(`.${select}`)?.parentNode;
        removeFieldErrors(fieldGroup);
      }
    });
  };

  const verifyRequiredField = (e, fieldName) => {
    const inputValue = e.target.value,
      fieldGroup = e.target.parentNode;
    if (fieldGroup) {
      if (!inputValue && !hasClass(fieldGroup, 'field-error')) {
        addFieldError(fieldGroup, fieldName);
      } else {
        removeFieldErrors(fieldGroup);
      }
    }
  };

  const addFieldError = (fieldGroup, fieldName) => {
    addClass(fieldGroup, 'has-error');
    const error = document.createElement('div');
    error.className = 'field-error';
    error.innerText = `${fieldName} is required`;
    if (Object.prototype.hasOwnProperty.call(fieldGroup, 'appendChild')) {
      fieldGroup.appendChild(error);
    }
  };

  const removeFieldErrors = (fieldGroup) => {
    removeClass(fieldGroup, 'has-error');
    const errors = fieldGroup?.getElementsByClassName('field-error');
    if (errors) {
      for (let error of errors) { // eslint-disable-line no-unused-vars
        error.remove();
      }
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    const requiredFields = [
      'first_name',
      'last_name',
      'has_significant_ownership',
      'job_title',
      'social_security_number',
      'date_of_birth',
      'home_address1',
      'home_city',
      'home_state',
      'home_zip_code'
    ];
    requiredFields.forEach((el) => {
      if (formState[el] === '') {
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const renderTitleCopy = () => (
    isAffiniPayApplicant ?
      'Add an Authorized Signer':
      'Add a Business Owner'
  );

  const renderOwnershipLabel = () => (
    isAffiniPayApplicant ?
      'Does this person have management responsibilities in the organization' :
      'Does this person own 25% or more of the business?'
  );

  const renderOwnershipClarification = () => (
    isAffiniPayApplicant ?
      'Only people who have management responsibilities of the organization need to be added.' :
      'Only people who own more than 25% of the business need to be added.'
  );

  return (
    <div className='row'>
      <div className='col-xs-12'>
        <h3>{renderTitleCopy()}</h3>
      </div>
      {errors?.length > 0 && (
        <div className="col-xs-12">
          <div className="alert alert-danger" role="alert">
            {errors.map((e, i) => (
              <p key={i}>{e.message}</p>
            ))}
          </div>
        </div>
      )}
      <form className='add-business-owner'>
        <div className='col-xs-6 form-group'>
          <APInputText
            label="First name"
            name='first_name'
            onChange={handleFieldChange}
            onBlur={(e) => {
              verifyRequiredField(e, 'First name');
            }}
            parentClass={`${isAdmin ? 'admin' : ''}`}
            required={true}
            value={formState['first_name']}
          />
        </div>
        <div className='col-xs-6 form-group'>
          <APInputText
            label="Last name"
            name='last_name'
            parentClass={`${isAdmin ? 'admin' : ''}`}
            onChange={handleFieldChange}
            onBlur={(e) => {
              verifyRequiredField(e, 'Last name');
            }}
            required={true}
            value={formState['last_name']}
          />
        </div>
        <div className='col-xs-12 form-group'>
          <div>
            <label className="ownership-label">{renderOwnershipLabel()}</label>
          </div>
          <APRadioButton
            name="has_significant_ownership"
            value={''}
            checked={false}
            text='No'
            onChange={(e) => {
              setIsValidOwner(false);
              handleFieldChange(e);
            }} />
          <APRadioButton
            name="has_significant_ownership"
            value={true}
            checked={true}
            text='Yes'
            onChange={(e) => {
              setIsValidOwner(true);
              handleFieldChange(e);
            }} />
        </div>
        {isValidOwner ? (
          <div>
            <div className='col-xs-12 form-group' onClick={(e) => {
              verifyRequiredSelect(e.currentTarget, 'job_title', 'Job title');
            }}>
              <div className={`${isAdmin ? 'admin' : ''}`} >
                <APSelect
                  label='Job title'
                  name="job_title"
                  options={initialJobsOptions}
                  onChange={handleFieldChange}
                  parentClass={`${isAdmin ? 'admin ' : ''}job_title`}
                  required={true}
                  value={formState['job_title']}
                />
              </div>
            </div>
            <div className='col-xs-6 form-group'>
              <APInputMask
                label="Social Security Number"
                mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                name="social_security_number"
                onBlur={(e) => {
                  verifyRequiredField(e, 'Social Security Number');
                }}
                onChange={handleFieldChange}
                parentClass={`${isAdmin ? 'admin' : ''}`}
                placeholder={owner && `***-**-${owner.social_security_number_last_4}`}
                required={true}
                value={formState['social_security_number']}
              />
            </div>
            <div className='col-xs-6 form-group'>
              <APInputMask
                label="Date of Birth"
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                name='date_of_birth'
                onBlur={(e) => {
                  verifyRequiredField(e, 'Date of Birth');
                }}
                onChange={handleFieldChange}
                parentClass={`${isAdmin ? 'admin' : ''}`}
                required={true}
                value={formState['date_of_birth']}
              />
            </div>
            <div className='col-xs-8 form-group'>
              <APInputText
                label="Home Address"
                name='home_address1'
                onBlur={(e) => {
                  verifyRequiredField(e, 'Home Address');
                }}
                onChange={handleFieldChange}
                parentClass={`${isAdmin ? 'admin' : ''}`}
                required={true}
                value={formState['home_address1']}
              />
            </div>
            <div className='col-xs-4 form-group'>
              <APInputText
                label="Apt, suite, etc"
                name='home_address2'
                onChange={handleFieldChange}
                parentClass={`${isAdmin ? 'admin' : ''}`}
                value={formState['home_address2']}
              />
            </div>
            <div className='col-xs-5 form-group'>
              <APInputText
                label="City"
                name='home_city'
                onBlur={(e) => {
                  verifyRequiredField(e, 'City');
                }}
                onChange={handleFieldChange}
                parentClass={`${isAdmin ? 'admin' : ''}`}
                required={true}
                value={formState['home_city']}
              />
            </div>
            <div className='col-xs-3 form-group' onClick={(e) => {
              verifyRequiredSelect(e.currentTarget, 'home_state', 'State');
            }}>
              <div className={`${isAdmin ? 'admin' : ''}`} >
                <APSelect
                  label='State'
                  name="home_state"
                  options={initialStatesOptions}
                  onChange={handleFieldChange}
                  parentClass='home_state'
                  placeholderText='Select...'
                  required={true}
                  value={formState['home_state']}
                />
              </div>
            </div>
            <div className='col-xs-4 form-group'>
              <APInputMask
                label="Postal Code"
                mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                name="home_zip_code"
                onChange={handleFieldChange}
                onBlur={(e) => {
                  verifyRequiredField(e, 'Postal Code');
                }}
                parentClass={`${isAdmin ? 'admin' : ''}`}
                required={true}
                value={formState['home_zip_code']}
              />
            </div>
          </div>
        ) : (
          <div className='col-xs-12'>
            <p><em>{renderOwnershipClarification()}</em></p>
          </div>
        )}
        <div className='col-xs-12 form-group'>
          <APButton className='ap-primary-button pull-right submit-btn'
            disabled={!isFormValid}
            onClick={(e) => {
              if (isFormValid) {
                e.preventDefault();
                if (owner) {
                  onEditSubmit(formState);
                } else {
                  onSubmit(formState);
                }
              } else {
                setIsFormValid(false);
              }
            }}>
            Submit
          </APButton>
          <APButton className='ap-secondary-button pull-right'
            onClick={onCancel}>
            Cancel
          </APButton>
        </div>
      </form>
    </div>
  );
};

AddOwnerForm.propTypes = {
  errors: array,
  onSubmit: func,
  onCancel: func,
  owner: object,
  onEditSubmit: func,
  isAdmin: bool,
  isAffiniPaySite: bool,
  showPuertoRicoState: bool
};

export default AddOwnerForm;
