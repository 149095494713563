import { APCheckbox, APInputMonetary, APInputPercent, APInputText } from 'affinipay-ui-library';
import { bool, number, string } from 'prop-types';
import React, { useState } from 'react';
import APRadioButton from '../../../components/APRadioButton/index';
import './style.scss';

function AdminSurchargeForm({
  surcharge_enabled = false,
  surcharge_type='percent',
  surcharge_amount=0,
  surcharge_label='',
  surcharge_percent=''
}) {

  const initialFormState = {
    surcharge_enabled: surcharge_enabled,
    surcharge_type: surcharge_type,
    surcharge_amount: surcharge_amount.toFixed(2),
    surcharge_label: surcharge_label,
    surcharge_percent: surcharge_percent
  };

  const [isSurchargeEnabled, setIsSurchargeEnabled] = useState(surcharge_enabled);
  const [selectedType, setSelectedType] = useState(surcharge_type);
  const [formState, setFormState] = useState(initialFormState);

  const handleCheckboxClick = e => {
    const checked = e.target.checked;
    setIsSurchargeEnabled(checked);
  };

  const handleRadioChange = e => {
    const value = e.target.value;
    setSelectedType(value);
  };

  const handleInputChange = e => {
    const {name, value} = e.target;
    setFormState({...formState, [name]: value});
  };

  return (
    <div className="form-group admin surcharge">
      <label className="col-sm-3 col-md-4 col-lg-3 control-label line-label">
          Enable Surcharge
      </label>
      <div className="col-sm-9 col-md-8 col-sm-9 checkbox">
        <APCheckbox
          text={isSurchargeEnabled ? 'Surcharge enabled' :'No Surcharge'}
          value='true'
          name='surcharge_enabled'
          checked={surcharge_enabled}
          disabled={false}
          onChange={handleCheckboxClick}
          displayType='toggle'
        />
        {isSurchargeEnabled && (
          <>
            <APRadioButton
              name="surcharge_type"
              value={'percent'}
              checked={selectedType==='percent'}
              text='Percentage'
              onChange={handleRadioChange}
            />
            {selectedType === 'percent' && (
              <div className="surcharge-container">
                <label className="surcharge-label">Label:</label>
                <APInputText
                  className="label-input"
                  name="surcharge_label"
                  onChange={handleInputChange}
                  parentClass="admin"
                  required={true}
                  value={formState['surcharge_label']}
                />
                <APInputPercent
                  className="amount-input"
                  name="surcharge_percent"
                  onChange={handleInputChange}
                  parentClass="admin"
                  required={true}
                  value={formState['surcharge_percent']}
                />
              </div>
            )}
            <APRadioButton
              name="surcharge_type"
              value={'flat'}
              checked={selectedType==='flat'}
              text='Flat Amount'
              onChange={handleRadioChange}
            />
            {selectedType === 'flat' && (
              <div className="surcharge-container">
                <label className="surcharge-label">Label:</label>
                <APInputText
                  name="surcharge_label"
                  value={formState['surcharge_label']}
                  className="label-input"
                  onChange={handleInputChange}
                  parentClass="admin"
                  required={true}
                />
                <APInputMonetary
                  name="surcharge_amount"
                  value={formState['surcharge_amount']}
                  onChange={handleInputChange}
                  parentClass="admin"
                  required={true}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

AdminSurchargeForm.propTypes = {
  surcharge_enabled: bool,
  surcharge_type: string,
  surcharge_amount: number,
  surcharge_label: string,
  surcharge_percent: string
};

export default AdminSurchargeForm;