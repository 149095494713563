import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { APInputText, APInputMask } from 'affinipay-ui-library';
import BillingAddressComponent from './BillingAddress';
import HostedFieldLabeled from '../../components/HostedFieldLabeled/index';
import { isFieldRequired, safeSendGA } from '../../lib/utils';
import { getFieldLabel } from '../../lib/paymentPage/optionalFields';

// eslint-disable-next-line react/display-name
const CardTabComponent = React.memo(({
  formState,
  setFormState,
  onChange,
  hostedFieldsState,
  optionalFields=[],
  privacy_policy_url,
  surchargeEnabled,
  isSurchargeable,
  disabledFieldsState,
  clientInfoState,
  setClientInfoState
}) => {

  useEffect(() => {
    if(surchargeEnabled) {
      if(isSurchargeable) {
        safeSendGA('Payment Page 2.0', 'Entered surchargeable card');
      } else {
        safeSendGA('Payment Page 2.0', 'Entered Non Surchargeable card after surchargeable');
      }
    }
  },[isSurchargeable]);

  const handleInputChange = (e, needsStateRest=false) => {
    const name = e.target.name,
      value = e.target.value;
    let newState = { [`${name}`]: value };
    if(needsStateRest) newState = {...newState, 'cardBillingState': undefined};
    setFormState({ ...formState, ...newState });
    if(onChange){
      onChange(e);
    }
  };

  return (
    <>
      <div className="col-12">
        <h3 className="card-information-title">Card Information</h3>
      </div>
      <div className="col-12 form-group">
        <APInputText
          isFloatingLabel={true}
          label={getFieldLabel('Name on Card', 'cardName', optionalFields)}
          value={formState.cardName}
          name='cardName'
          required={isFieldRequired('cardName', optionalFields)}
          onChange={handleInputChange}
          disabled={disabledFieldsState['cardName']}
        />
      </div>
      <div className="col-12 col-sm-6 form-group">
        <HostedFieldLabeled
          id="cc_number"
          hostedFieldsState={hostedFieldsState}
          label="Card Number"
          disabled={disabledFieldsState['cc_number']}
        />
      </div>
      <div className="col-5 col-sm-3 form-group">
        <HostedFieldLabeled
          id="cvv"
          hostedFieldsState={hostedFieldsState}
          label={getFieldLabel('CVV', 'cardCVV', optionalFields)}
          isRequired={optionalFields.indexOf('cardCVV') === -1}
          disabled={disabledFieldsState['cvv']}
        />
      </div>
      <div className="col-7 col-sm-3 form-group">
        <APInputMask
          disabled={disabledFieldsState['cardExpiration']}
          isFloatingLabel={true}
          label="Exp. Date"
          mask={[/[0-9]/,/[0-9]/,'/','2','0',/[2-9]/,/[0-9]/]}
          name='cardExpiration'
          onChange={handleInputChange}
          required={true}
          value={formState.cardExpiration}
        />
      </div>
      <BillingAddressComponent
        formState={formState}
        setFormState={setFormState}
        handleInputChange={handleInputChange}
        prefix="card"
        optionalFields={optionalFields}
        disabledFieldsState={disabledFieldsState}
        clientInfoState={clientInfoState}
        setClientInfoState={setClientInfoState}
      />
      <div className="col-12 payment-disclaimer">
      By clicking on the Pay Button, you agree to pay the above amount and provide your information to help us process your payment and detect fraud.
      Please see our <a href={`${privacy_policy_url}`} rel="noopener noreferrer" target="_blank">Privacy Policy</a> for more information on our data practices.
      </div>
    </>
  );
});

CardTabComponent.propTypes = {
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  onChange: PropTypes.func,
  hostedFieldsState: PropTypes.object,
  isRequired: PropTypes.array,
  optionalFields: PropTypes.array,
  privacy_policy_url: PropTypes.string,
  surchargeEnabled: PropTypes.bool,
  disabledFieldsState: PropTypes.object,
  isSurchargeable: PropTypes.bool,
  clientInfoState: PropTypes.object,
  setClientInfoState: PropTypes.func
};

export default CardTabComponent;
