import React from 'react';
import { shape, arrayOf, number, string, bool } from 'prop-types';
import LegalEntity from './LegalEntity';

const LegalEntities = ({
  merchant_id: merchantId,
  legal_entities: legalEntities,
  can_edit_beneficial_owners: canEditBeneficialOwners = false,
  can_edit_legal_entities: canEditLegalEntities = false,
  show_puerto_rico_state: showPuertoRicoState = false
}) => (
  <div className='admin'>
    {legalEntities?.data?.length ?
      <>
        {legalEntities?.data?.map((le) => (
          <LegalEntity
            key={le?.attributes?.id}
            legalEntity={le}
            canEditBeneficialOwners={canEditBeneficialOwners}
            canEditLegalEntities={canEditLegalEntities}
            merchantId={merchantId}
            showPuertoRicoState={showPuertoRicoState}
          />
        ))}
      </>
      :
      <h3>No legal information has been added.</h3>
    }
  </div>
);

export default LegalEntities;

LegalEntities.propTypes = {
  merchant_id: number,
  can_edit_beneficial_owners: bool,
  can_edit_legal_entities: bool,
  show_puerto_rico_state: bool,
  legal_entities: shape({
    data: arrayOf(shape({
      id: string,
      type: string,
      attributes: shape({
        id: number,
        merchant_id: number,
        phone_number: string,
        url: string,
        email: string,
        legal_business_name: string,
        doing_business_as: string,
        merchant_category_code: string,
        years_in_business: number,
        business_structure: string,
        address: shape({
          address1: string,
          address2: string,
          city: string,
          zip_code: string
        }),
        beneficial_owners: arrayOf(shape({
          id: number,
          given_name: string,
          surname: string
        }))
      })
    }))
  })
};
