import React, {useState} from 'react';
import { APButton, APModal } from 'affinipay-ui-library';
import AddOwnerForm from '../AddOwnerForm';
import OwnersList from '../OwnersList';
import {array, bool, object} from 'prop-types';
import './style.scss';
import {createOwner, deleteOwner, patchOwner} from './services';

const AdditionalOwners = ({paths, initialOwners=[], displayPolicy, isAdmin=false, isAffiniPaySite, isDisabled, showPuertoRicoState}) => {
  const [isFormShown, setIsFormShown] = useState(false);
  const [owners, setOwners] = useState(initialOwners);
  const [errors, setErrors] = useState([]);
  const [ownerToEdit, setOwnerToEdit] = useState(undefined);

  const handleSubmit = async (owner) => {
    const response = await createOwner(paths.create, owner);
    let json = await response.json();
    if (response.status >= 200 && response.status <= 299) {
      setOwners([...owners, json]);
      setIsFormShown(false);
      setErrors([]);
    }else {
      setErrors(json);
    }
  };

  const handleRemove = async (owner) => {
    const uri = paths.destroy.replace('{id}', owner.id);
    const response = await deleteOwner(uri, owner);
    if (response.status === 204) {
      setOwners(owners.filter((x) => {
        return x.id != owner.id;
      }));
      setErrors([]);
    }else {
      const json = await response.json();
      setErrors(json);
    }
  };

  const handleEditButtonClick = (e, owner) => {
    e.preventDefault();
    setOwnerToEdit(owner);
    setIsFormShown(true);
  };

  const handleEditFormSubmit = async (owner) => {
    const uri = paths.destroy.replace('{id}', owner.id);
    const response = await patchOwner(uri, owner);
    const json =  await response.json();
    if (response.status >= 200 && response.status <= 299) {
      const newOwners = owners.map((el) => {
        if(el.id===json.id){
          el = json;
        }
        return el;
      });
      setOwners(newOwners);
      setIsFormShown(false);
      setOwnerToEdit(undefined);
      setErrors([]);
    }else {
      let json = await response.json();
      setErrors(json);
    }
  };

  const closeModal = () => {
    setOwnerToEdit(undefined);
    setIsFormShown(!isFormShown);
  };

  const handleAddOwnerClick = (e) => {
    e.preventDefault();
    setIsFormShown(!isFormShown);
  };

  const renderSiteSpecificHeader = () => {
    const partyTitle = !isAdmin && isAffiniPaySite ? 'Authorized Signers' : 'Business Owners';
    return `Additional ${partyTitle}`;
  };

  const renderSiteSpecificCopy = () => {
    const titleDefinition = !isAdmin && isAffiniPaySite
      ? 'Authorized Signers are individuals with'
      : 'Business owners are individuals with an ownership stake or';
    const primaryOwnersRequest = isAffiniPaySite ? '' : 'Please list everyone who owns 25% or more of the company.';
    const criteriaRequest = `${isAffiniPaySite ? 'List' : 'And if no one meets this criteria, list'} at least one ${isAffiniPaySite ? 'authorized signer' : 'business owner'} with management responsibilities.`;
    return `${titleDefinition} significant management responsibilities, like ${isAffiniPaySite ? 'an owner,' : 'a'} CEO or CFO. ${primaryOwnersRequest} ${criteriaRequest}`;
  };

  const renderSiteSpecificButtonLabel = () => !isAdmin && isAffiniPaySite ? '+ ADD AUTHORIZED SIGNER' : '+ ADD BUSINESS OWNER';

  return (
    <div className="row">
      <div className="col-xs-12">
        <hr/>
        <h3>{renderSiteSpecificHeader()}</h3>
        {!isAdmin && (
          <>
            <p>
              {renderSiteSpecificCopy()}
            </p>
            <p>
              If you don&apos;t have all of their information now, you can save
              and come back later or follow up after you submit your
              application.
            </p>
          </>
        )}
      </div>
      <div className="col-xs-12 button-row">
        <APButton
          className={'ap-secondary-button'}
          disabled={isDisabled}
          onClick={handleAddOwnerClick}>{renderSiteSpecificButtonLabel()}</APButton>
        <APModal isActiveInitial={isFormShown}
          onClose={closeModal}>
          <div className="container-fluid">
            <AddOwnerForm
              onSubmit={handleSubmit}
              onEditSubmit={handleEditFormSubmit}
              onCancel={closeModal}
              owner={ownerToEdit}
              errors={errors}
              isAffiniPaySite={isAffiniPaySite}
              isAdmin={isAdmin}
              showPuertoRicoState={showPuertoRicoState}
            />
          </div>
        </APModal>
      </div>
      {owners.length > 0 && (
        <div className="col-xs-12">
          <OwnersList
            owners={owners}
            displayPolicy={displayPolicy}
            handleEdit={handleEditButtonClick}
            handleRemove={handleRemove}
            isAdmin={isAdmin}
            isAffiniPaySite={isAffiniPaySite}
          />
        </div>
      )}
    </div>
  );
};

AdditionalOwners.propTypes = {
  paths: object,
  initialOwners: array,
  displayPolicy: object,
  isAdmin: bool,
  isAffiniPaySite: bool,
  isDisabled: bool,
  showPuertoRicoState: bool
};

export default AdditionalOwners;
