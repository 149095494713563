import {
  DateCell,
  AmountCell,
  AccountCell,
  NameReferenceCell,
  SurchargeCell,
  TotalAmountCell
} from './cellComponents';

const tableColumns = [
  {
    Header: 'Date',
    accessor: 'invoiceDate',
    disableSortBy: true,
    Cell: DateCell
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    disableSortBy: true,
    Cell: AmountCell
  },
  {
    Header: 'Deposit Account',
    accessor: 'bankDetails',
    disableSortBy: true,
    Cell: AccountCell
  },
  {
    Header: 'Name/Reference',
    accessor: 'nameReference',
    disableSortBy: true,
    Cell: NameReferenceCell
  },
  {
    Header: 'Surcharge',
    accessor: 'depositDetails',
    disableSortBy: true,
    Cell: SurchargeCell
  },
  {
    Header: 'Total Amount',
    accessor: 'paymentTotal',
    headerClassName: 'amount-header',
    disableSortBy: true,
    Cell: TotalAmountCell
  }
];

export default tableColumns;
