import React, {useState} from 'react';
import {array, bool, string} from 'prop-types';
import { APButton, APModal, APToast } from 'affinipay-ui-library';
import QuickBillForm from './QuickBillForm';
import './style.scss';
import {dispatchQuickBillCreatedEvent} from '../utils';
import { sendEvent, EventNames } from '../../../lib/intercom';
import IconEQB from '../icons/icon-eqb';

/* eslint-disable no-undef, react/jsx-no-undef */
const QuickBillModal = ({
  bankAccounts,
  merchantId,
  subject,
  body,
  referenceLabel,
  isOnQbIndex = false,
  attachmentDisabled,
  siteSupportPhone
}) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const onSuccess = () => {
    setDisplayModal(false);
    if (isOnQbIndex) {
      dispatchQuickBillCreatedEvent();
    }
    setShowToast(true);
  };

  const openModal = () => {
    const mobileHeaderIcon = document.querySelector('#hamburger-button');
    if(mobileHeaderIcon) {
      mobileHeaderIcon.style.visibility = 'hidden';
    }
    sendEvent(EventNames.quickBillButtonClicked);
    if(window.gtag) {
      gtag('event', 'click', {
        event_category: 'Charge',
        event_label: 'QuickBill link'
      });
    }
    setDisplayModal(true);
  };

  const closeModal = () => {
    const mobileHeaderIcon = document.querySelector('#hamburger-button');
    if(mobileHeaderIcon) {
      mobileHeaderIcon.style.visibility = 'visible';
    }
    setDisplayModal(false);
  };

  return (
    <>
      <APButton
        className="ap-secondary-button send-quick-bill uppercase"
        onClick={openModal}
      >
        <IconEQB
          className="send-quick-bill-icon"
          title="Send Quick Bill Icon"
          size="24"
        />
        <span className="quick-bill-button-text">Send Quick Bill</span>
      </APButton>
      <APModal isActiveInitial={displayModal}
        onClose={closeModal}
        className='quick-bill-modal'
        headerText='New Quick Bill'>
        <QuickBillForm
          bankAccounts={bankAccounts}
          subject={subject}
          body={body}
          referenceLabel={referenceLabel}
          merchantId={merchantId}
          attachmentDisabled={attachmentDisabled}
          siteSupportPhone={siteSupportPhone}
          onSuccess={onSuccess}
          onCancel={closeModal}
        />
      </APModal>
      {showToast && (
        <APToast
          isOpen={true}
          type={'success'}
          onClose={() => setShowToast(false)}
          fadeOutTime={5000}
        >
          <div>Quick Bill created successfully.</div>
        </APToast>
      )}
    </>
  );
};

export default QuickBillModal;

QuickBillModal.propTypes = {
  bankAccounts: array.isRequired,
  merchantId: string.isRequired,
  subject: string,
  body: string,
  referenceLabel: string,
  isOnQbIndex: bool.isRequired,
  attachmentDisabled: bool,
  siteSupportPhone: string
};
/* eslint-enable no-undef, react/jsx-no-undef */
