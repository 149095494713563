import React from 'react';
import PropTypes from 'prop-types';
import { APInputText } from 'affinipay-ui-library';
import APRadioButton from '../../components/APRadioButton/index';
import BillingAddressComponent from './BillingAddress';
import HostedFieldLabeled from '../../components/HostedFieldLabeled/index';
import { isFieldRequired } from '../../lib/utils';

// eslint-disable-next-line react/display-name
const EcheckTabComponent = React.memo(({
  formState,
  setFormState,
  onChange,
  merchant,
  frequencyText,
  optionalFields=[],
  setOptionalFields,
  hostedFieldsState,
  privacy_policy_url,
  disabledFieldsState,
  clientInfoState,
  setClientInfoState
}) => {

  const handleInputChange = (e, needsStateRest=false) => {
    const name = e.target.name,
      value = e.target.value;
    let newState = { [`${name}`]: value };
    if(needsStateRest) newState = {...newState, 'echeckBillingState': undefined};
    setFormState({ ...formState, ...newState });
    if(onChange){
      onChange(e);
    }
  };

  const handleRadioButtonChange = e => {
    const value = e.target.value;
    let newState = [];
    let newFormState = {};
    if(value !== formState.echeckAccountType) {
      if ( value.indexOf('individual') > -1 ) {
        newState = optionalFields.filter( e => e !== 'echeckFirstName' && e !== 'echeckLastName' );
        newState = [...newState, 'echeckHolderName'];
        if(value.indexOf('Checking') > -1) {
          newFormState = {echeckAccountOwnerType : 'individual', echeckAccountType: 'checking'};
        } else if (value.indexOf('Savings') > -1) {
          newFormState = {echeckAccountOwnerType : 'individual', echeckAccountType: 'savings'};
        }
      } else if( value.indexOf('business') > -1) {
        newState = optionalFields.filter( e => e !== 'echeckHolderName' );
        newState = [...newState, 'echeckFirstName', 'echeckLastName'];
        if(value.indexOf('Checking') > -1) {
          newFormState = {echeckAccountOwnerType : 'business', echeckAccountType: 'checking'};
        } else if (value.indexOf('Savings') > -1) {
          newFormState = {echeckAccountOwnerType : 'business', echeckAccountType: 'savings'};
        }
      }
      setOptionalFields(newState);
    }
    setFormState({ ...formState, ...newFormState });
    if(onChange) {
      onChange(e);
    }
  };

  return (
    <>
      <div className="col-12">
        <h3>Bank Account Information</h3>
      </div>
      <div className="col-lg-5 col-12">
        <APRadioButton
          name="echeckAccountType"
          value='individualChecking'
          checked={true}
          text='Personal Checking Account'
          onChange={handleRadioButtonChange}/>
      </div>
      <div className="col-lg-7 col-12">
        <APRadioButton
          name="echeckAccountType"
          value='businessChecking'
          checked={false}
          text='Business Checking Account'
          labelClassNames="business-checkbox checking"
          onChange={handleRadioButtonChange}/>
      </div>
      <div className="col-lg-5 col-12">
        <APRadioButton
          name="echeckAccountType"
          value='individualSavings'
          checked={false}
          labelClassNames="ind-sav-radio"
          text='Personal Savings Account'
          onChange={handleRadioButtonChange}/>
      </div>
      <div className="col-lg-7 col-12">
        <APRadioButton
          name="echeckAccountType"
          value='businessSavings'
          checked={false}
          text='Business Savings Account'
          labelClassNames="business-checkbox"
          onChange={handleRadioButtonChange}/>
      </div>
      {formState.echeckAccountOwnerType === 'individual' ? (
        <>
          <div className="col-12 col-sm-6 form-group">
            <APInputText
              isFloatingLabel={true}
              label={'First Name'}
              value={formState.echeckFirstName}
              name="echeckFirstName"
              onChange={handleInputChange}
              required={isFieldRequired('echeckFirstName', optionalFields)}
            />
          </div>
          <div className="col-12 col-sm-6 form-group">
            <APInputText
              isFloatingLabel={true}
              label={'Last Name'}
              value={formState.echeckLastName}
              name="echeckLastName"
              onChange={handleInputChange}
              required={isFieldRequired('echeckLastName', optionalFields)}
            />
          </div>
        </>
      ) : (
        <div className="col-12 form-group">
          <APInputText
            isFloatingLabel={true}
            label={'Name of Business'}
            value={formState.echeckHolderName}
            name="echeckHolderName"
            onChange={handleInputChange}
            required={isFieldRequired('echeckHolderName', optionalFields)}
          />
        </div>
      )}
      <div className="col-12 col-sm-6 form-group">
        <HostedFieldLabeled
          id="accountNumber"
          hostedFieldsState={hostedFieldsState}
          label="Account Number"
        />
      </div>
      <div className="col-12 col-sm-6 form-group">
        <HostedFieldLabeled
          id="routingNumber"
          hostedFieldsState={hostedFieldsState}
          label="Routing Number"
        />
      </div>
      <BillingAddressComponent
        formState={formState}
        setFormState={setFormState}
        handleInputChange={handleInputChange}
        prefix="echeck"
        optionalFields={optionalFields}
        disabledFieldsState={disabledFieldsState}
        clientInfoState={clientInfoState}
        setClientInfoState={setClientInfoState}
      />
      {(!frequencyText || frequencyText === 'NONE') ? (
        <div className="col-12 payment-disclaimer">
          By clicking on the Pay Button, I am authorizing {merchant.name} to
          initiate a one-time ACH/electronic debit in the amount indicated from
          the bank account I have designated above. The debit is to occur
          approximately 3-5 business days from submission and I understand that
          the authorization will remain in full force and effect until the
          transaction is cancelled by me by contacting {merchant.name}, or the
          ACH/electronic debit is processed from the designated account. Any
          request for cancellation shall afford {merchant.name} a reasonable
          opportunity to act on such request. I certify that (1) I am authorized
          to debit the bank account above and (2) that the ACH/electronic
          payment I am authorizing complies with all applicable laws. By
          clicking on the Pay Button, you agree to pay the above amount and
          provide your information to help process your payment and detect
          fraud. Please see our{' '}
          <a href={`${privacy_policy_url}`} rel="noopener noreferrer" target="_blank">
            Privacy Policy
          </a>{' '}for more information on our data practices.
        </div>
      ) : (
        <div className="col-12 payment-disclaimer">
          By clicking on the Pay Button, I am authorizing {merchant.name} to
          initiate a recurring ACH/electronic debit in the amount indicated from
          the bank account I designated above. The debit is to occur
          approximately 3-5 business days from submission and continue
          {' ' + frequencyText}. I understand that this authorization will
          remain in full force and effect until I revoke authorization by
          contacting {merchant.name}, or the total amount owed as agreed upon
          with {merchant.name} has been fully processed from the designated
          account. Any such request for cancellation shall afford{' '}
          {merchant.name} a reasonable opportunity to act on such request prior
          to the initiation of the subsequent debit. I certify that (1) I am
          authorized to debit the bank account above and (2) that the
          ACH/electronic payment I am authorizing complies with all applicable
          laws. By clicking on the Pay Button, you agree to pay the above amount
          and provide your information to help us process your payment and
          detect fraud. Please see our{' '}
          <a href={`${privacy_policy_url}`} rel="noopener noreferrer" target="_blank">
            Privacy Policy
          </a>{' '}for more information on our data practices.
        </div>
      )}
    </>
  );
});

EcheckTabComponent.propTypes = {
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  onChange: PropTypes.func,
  optionalFields: PropTypes.array,
  setOptionalFields: PropTypes.func,
  hostedFieldsState: PropTypes.object,
  optionalFieldsFromServer: PropTypes.string,
  disabledFieldsState: PropTypes.object,
  merchant: PropTypes.object,
  frequencyText: PropTypes.string,
  privacy_policy_url: PropTypes.string,
  clientInfoState: PropTypes.object,
  setClientInfoState: PropTypes.func
};

export default EcheckTabComponent;
