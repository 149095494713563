import React, {useState} from 'react';
import './style.scss';
import { APFormMessageBox } from 'affinipay-ui-library';
import { bool } from 'prop-types';

const RecurringChargeFailedTransactionError = ({fromScheduledPaymentsDetail = false}) => {
  const [hasBeenDismissed, setHasBeenDismissed] = useState(false);

  const dismissError = () => {
    if (!fromScheduledPaymentsDetail) {
      document.cookie = 'occurrence_failed_error_dismissed=true;';
    }
    setHasBeenDismissed(true);
  };

  if(!hasBeenDismissed) {
    return (
      <div className='recurring-charge-error'>
        <APFormMessageBox type="error">
          <div className='failed-occurrence-error'>
            <div className="error-action-message">
              <span><b>ACTION REQUIRED:</b> {fromScheduledPaymentsDetail? 'You have a Failed transaction. Please retry.' : 'You have a Failed Transaction. Click on the alert in the table to view more details.'} </span>
            </div>
            <div className='dismiss-error' onClick={dismissError} />
          </div>
        </APFormMessageBox>
      </div>
    );
  }

  return null;
};

RecurringChargeFailedTransactionError.propTypes = {
  fromScheduledPaymentsDetail: bool
};

export default RecurringChargeFailedTransactionError;